<template>
  <div class="w-full max-h-screen">
    <div class="mx-auto w-1/2 h-full py-52 sm:py-10">
      <div>
        <h1 class="sm:text-3xl text-xl italic text-center">
          Awaiting Approval...
        </h1>
        <div class="md:w-4/5 mx-auto">
          <bus-stop></bus-stop>
        </div>
        <div class="flex justify-end">
          <button
            class="
              shadow-md
              border-custom_gray
              bg-white
              py-3
              px-8
              transition-colors
              hover:bg-primary hover:text-white
              text-lg
              ease-linear
              rounded-md
              delay-200
              flex
            "
            @click="signOut()"
          >
            <logout-icon :color="'text-black'" class="mr-2"></logout-icon>
            log out
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import busStop from '../components/Icons/busStop.vue'
import LogoutIcon from '../components/Icons/logoutIcon.vue'
export default {
  components: { busStop, LogoutIcon },
  methods: {
    signOut() {
      this.$store.dispatch('signOut')
    }
  },
  beforeMount() {
    this.$store.dispatch('middleware/isLogged')
  }
}
</script>

<style></style>
